/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BaseFilter } from '../model/models';
import { CalculationResource } from '../model/models';
import { CreateCalculationRequest } from '../model/models';
import { Model7e50839d6949dd6964b5452ecc82c3c0500Response } from '../model/models';
import { UpdateCalculationRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CalculationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Fetch HTML content from a specific URL
     * 
     */
    _7e50839d6949dd6964b5452ecc82c3c0(extraHttpRequestParams?: any): Observable<string>;

    /**
     * Saves new calculation to database
     * 
     * @param createCalculationRequest 
     */
    createNewCalculation(createCalculationRequest?: CreateCalculationRequest, extraHttpRequestParams?: any): Observable<CalculationResource>;

    /**
     * Creates PDF for calculation
     * 
     * @param id 
     * @param credit 
     * @param investment 
     * @param rent 
     * @param mortgageIndices 
     */
    createPdfForCalculation(id: string, credit?: boolean, investment?: boolean, rent?: boolean, mortgageIndices?: string, extraHttpRequestParams?: any): Observable<Blob>;

    /**
     * Deletes calculation with given by ID
     * 
     * @param id 
     */
    deleteCalculation(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Find calculation by given ID
     * 
     * @param id 
     */
    getCalculationById(id: string, extraHttpRequestParams?: any): Observable<CalculationResource>;

    /**
     * Get calculation info for PDF creation
     * 
     * @param id 
     */
    getCalculationInfo(id: string, extraHttpRequestParams?: any): Observable<CalculationResource>;

    /**
     * Get overview of all users calculation
     * 
     * @param filter Get calculations by filter
     * @param limit Calculation limit
     */
    getCalculationLists(filter?: BaseFilter, limit?: number, extraHttpRequestParams?: any): Observable<Array<CalculationResource>>;

    /**
     * Get last record for fin data
     * 
     */
    getLastRecordForFinData(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Updates an existing calculation
     * 
     * @param id 
     * @param updateCalculationRequest 
     */
    updateCalculation(id: string, updateCalculationRequest?: UpdateCalculationRequest, extraHttpRequestParams?: any): Observable<CalculationResource>;

}
